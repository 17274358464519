import React, { Component } from 'react'
import ResourcesTemplate from '../../components/resources/template'

import "../../styles/resources.scss";

import img from "../../images/resources/productbrief.png";


class ProductBrief extends Component {
  render() {
    return (
      <ResourcesTemplate
      header="Product Brief"
      subheader="This template puts your facts,assumption, and other relevant information regarding design projects in writing."
      image={img}
      url="#"   
      />
    )
  }
}

export default ProductBrief